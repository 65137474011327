<template>
  <div class="layer" v-if="visible">
    <div class="layer__inner" :style="`max-width: ${width}`">
      <header class="layer__header" :class="{ 'with-title': title }">
        <span>{{ title }}</span>
        <button class="layer__header__close" v-if="closable" @click="closeLayer">×</button>
      </header>
      <main class="layer__main" :style="`padding: ${padding}`">
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '30rem'
    },
    padding: {
      type: String,
      default: '0'
    },
    title: {
      type: String
    }
  },
  created: function() {
    window.addEventListener('keydown', this.onKeyPress)
  },
  beforeDestroy: function() {
    window.removeEventListener('keydown', this.onKeyPress)
  },

  methods: {
    closeLayer: function() {
      this.$emit('close')
    },
    onKeyPress: function(e) {
      if (e.key === 'Escape') {
        this.closeLayer()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.layer {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 2rem;
  overflow-y: scroll;

  background: rgba(darken($color-main, 20), 0.8);

  &__inner {
    width: 100%;
    margin: auto;
    position: relative;
    overflow: hidden;

    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }

  &__header {
    display: flex;
    padding: 1rem;
    justify-content: space-between;

    background: $color-main;

    color: #fff;
    font-size: 1.25rem;
    font-weight: $weight-bold;

    &__close {
      @extend %cleanbutton;

      padding: 0 0.5rem;
    }
  }
}
</style>
