<template>
  <BaseLoader v-if="!$store.getters.isLoaded(['organizations'])" />
  <div v-else>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center flex-grow-1">
        <h1 class="h2 mr-4">Organizations</h1>
        <ul class="nav nav-tabs flex-grow-1">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: showType === 'clients' }"
              href="#"
              @click.prevent="showType = 'clients'"
            >
              Clients
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: showType === 'demo' }" href="#" @click.prevent="showType = 'demo'">
              Demo
            </a>
          </li>
        </ul>
      </div>
      <button class="btn btn-primary ml-4 mr-2" @click="showOrganizationModal = true">New Organization</button>
      <DotMenu>
        <li @click="generateChristmasCatalogLetters">Generate Christmas Catalog Letters</li>
      </DotMenu>
    </div>

    <table class="table table-hover">
      <thead class="thead-light">
        <tr>
          <th scope="col">Name</th>

          <!-- <th scope="col" v-if="$store.state.role === 'admin'">Revenue (4W / 12W / total)</th>
          <th scope="col" v-if="$store.state.role === 'admin'">Profit (4W / 12W / total)</th> -->
          <th scope="col">Catalog Demand</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="organization in organizations" :key="organization.id">
          <td>
            <router-link :to="{ name: 'organization', params: { organizationId: organization.id } }">
              {{ organization.name }}
            </router-link>
            <small v-if="organization.parentOrganization" class="text-secondary ml-1"
              >🖇 {{ organization.parentOrganization.name }}</small
            >
            <span v-if="organization.features.kiosk" class="badge badge-info ml-1">Kiosk</span>
            <span v-if="organization.demo" class="badge badge-info ml-1">Demo</span>
          </td>
          <!-- <td v-if="$store.state.role === 'admin'">
            <span
              :class="{
                'text-success': _.get(organization, 'statistics.revenue.last4Weeks', 0) > 0,
                'text-danger': _.get(organization, 'statistics.revenue.last4Weeks', 0) < 0
              }"
            >
              {{ formatCurrency(_.get(organization, 'statistics.revenue.last4Weeks', '-/-')) }}
            </span>
            /
            <span
              :class="{
                'text-success': _.get(organization, 'statistics.revenue.last12Weeks', 0) > 0,
                'text-danger': _.get(organization, 'statistics.revenue.last12Weeks', 0) < 0
              }"
            >
              {{ formatCurrency(_.get(organization, 'statistics.revenue.last12Weeks', '-/-')) }}
            </span>
            /
            <span
              :class="{
                'text-success': _.get(organization, 'statistics.revenue.total', 0) > 0,
                'text-danger': _.get(organization, 'statistics.revenue.total', 0) < 0
              }"
            >
              {{ formatCurrency(_.get(organization, 'statistics.revenue.total', '-/-')) }}
            </span>
          </td>
          <td v-if="$store.state.role === 'admin'">
            <span
              :class="{
                'text-success': _.get(organization, 'statistics.profit.last4Weeks', 0) > 0,
                'text-danger': _.get(organization, 'statistics.profit.last4Weeks', 0) < 0
              }"
            >
              {{ formatCurrency(_.get(organization, 'statistics.profit.last4Weeks', '-/-')) }}
            </span>
            /
            <span
              :class="{
                'text-success': _.get(organization, 'statistics.profit.last12Weeks', 0) > 0,
                'text-danger': _.get(organization, 'statistics.profit.last12Weeks', 0) < 0
              }"
            >
              {{ formatCurrency(_.get(organization, 'statistics.profit.last12Weeks', '-/-')) }}
            </span>
            /
            <span
              :class="{
                'text-success': _.get(organization, 'statistics.profit.total', 0) > 0,
                'text-danger': _.get(organization, 'statistics.profit.total', 0) < 0
              }"
            >
              {{ formatCurrency(_.get(organization, 'statistics.profit.total', '-/-')) }}
            </span>
          </td> -->
          <td>
            <input
              type="checkbox"
              class="mr-2"
              :id="organization.id"
              @change="toggleCatalogDemandCheck"
              :checked="organization.catalogDemandChecked"
            />
            <button class="btn btn-sm btn-outline-secondary" @click="setCatalogDemand(organization.id)">
              {{ typeof organization.catalogDemand === 'undefined' ? '-/-' : organization.catalogDemand }}
            </button>
            <button class="btn btn-sm btn-outline-secondary ml-2" @click="setCatalogDemandNote(organization)">
              📝
            </button>
            <small class="" v-if="organization.catalogDemandNote"
              >{{ organization.catalogDemandNote }}
              <button
                class="btn btn-sm btn-outline-danger"
                style="font-size: 0.75rem"
                @click="deleteCatalogDemandNote(organization)"
              >
                x
              </button></small
            >
          </td>
        </tr>
      </tbody>
    </table>

    <Layer v-if="showOrganizationModal" @close="showOrganizationModal = false" width="600px" title="New Organization">
      <form class="pa-4">
        {{ errors }}
        <TextField
          label="Name"
          placeholder="e.g. Seniorenstift Sonnenschein Hannover"
          v-model="form.name"
          @blur="createLoginRecommendation"
          required
        />
        <TextField label="User" v-model="form.login" placeholder="e.G. sonnenschein-hannover" required />
        <TextField label="Email to receive login credentials" type="email" v-model="form.email" required />

        <div class="org-btn-wrap">
          <Button @click="addNewOrganization">Save</Button>
        </div>
      </form>
    </Layer>
  </div>
</template>

<script>
import { fb, db, functions } from '@/shared/firebase'
import Layer from '@/components/Layer'
import TextField from '@/shared/TextField'
import Button from '@/shared/Button'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
import DotMenu from '@/shared/DotMenu'

export default {
  components: { Layer, TextField, Button, DotMenu },
  data() {
    return {
      showOrganizationModal: false,
      showType: 'clients',
      form: {
        name: '',
        login: '',
        email: 'ralph@mitemma.de'
      },
      errors: null
    }
  },
  computed: {
    organizations() {
      const organizations = this.$store.state.organizations.map(organization => {
        const data = {
          ...organization,
          id: organization.id
        }
        return data
      })
      return _.sortBy(_.filter(organizations, { demo: this.showType === 'demo' }), ['name'])
    }
  },
  methods: {
    addNewOrganization() {
      const createNewOrganization = functions.httpsCallable('createOrganization')
      createNewOrganization(this.form)
        .then(() => {
          this.showOrganizationModal = false
        })
        .catch(e => {
          this.errors = e
        })
    },
    createLoginRecommendation() {
      const { name, login } = this.form

      if (login === '') {
        let value = name.toLocaleLowerCase()
        value = value.replace(/ä/g, 'ae')
        value = value.replace(/ö/g, 'oe')
        value = value.replace(/ü/g, 'ue')
        value = value.replace(/ß/g, 'ss')
        value = value.replace(/ /g, '-')
        value = value.replace(/\./g, '')
        value = value.replace(/,/g, '')
        value = value.replace(/\(/g, '')
        value = value.replace(/\)/g, '')
        this.form.login = value
      }
    },
    openOrganizationPage(organization) {
      this.$router.push({ name: 'organization', params: { organizationId: organization.name } }) //change to id
    },
    setCatalogDemand(organizationId) {
      const organization = this.$store.state.organizations.find(o => o.id === organizationId)
      let catalogDemand = prompt('Enter Catalog Demand for ' + organization.name)
      if (catalogDemand === null) return null
      if (!isNaN(parseFloat(catalogDemand))) {
        catalogDemand = parseFloat(catalogDemand)
        db.doc(`organizations/${organizationId}`).update({ catalogDemand })
      } else {
        db.doc(`organizations/${organizationId}`).update({ catalogDemand: fb.firestore.FieldValue.delete() })
      }
    },
    setCatalogDemandNote(organization) {
      const catalogDemandNote = prompt(`Note for ${organization.name}?`)
      if (catalogDemandNote) {
        db.doc(`organizations/${organization.id}`).update({ catalogDemandNote })
      }
    },
    deleteCatalogDemandNote(organization) {
      const confirmed = confirm(`Delete note for ${organization.name}?`)
      if (confirmed) {
        db.doc(`organizations/${organization.id}`).update({
          catalogDemandNote: fb.firestore.FieldValue.delete()
        })
      }
    },
    toggleCatalogDemandCheck(e) {
      const { id, checked } = e.target
      db.doc(`organizations/${id}`).update({
        catalogDemandChecked: checked
      })
    },
    generateChristmasCatalogLetters() {
      const dateString = new Date().toLocaleDateString('de-De', { year: 'numeric', month: 'long', day: 'numeric' })

      const getPt = mm => mm * 2.835

      const docDefinition = {
        pageSize: 'A4',
        pageMargins: [getPt(25), getPt(45), getPt(30), getPt(25)],
        content: [],
        defaultStyle: {
          lineHeight: 1.3,
          fontSize: 11
        },
        styles: {
          senderAddress: { fontSize: 8, color: '#555', margin: [0, 0, 0, getPt(4)] },
          customerAddress: { margin: [0, 0, 0, getPt(20)] },
          date: { alignment: 'right' },
          headline: { bold: true }
        }
      }

      const organizations = this.$store.state.organizations.filter(o => o.catalogDemand)

      organizations.forEach((organization, index) => {
        const isLastItem = index + 1 === organizations.length

        const { shippingAddress } = organization

        if (!shippingAddress) return console.warn(`No shipping address found for ${organization.name}`)

        const address = [
          `${organization.name}\n`,
          `${shippingAddress.street} ${shippingAddress.streetNumber}\n`,
          `${shippingAddress.zip} ${shippingAddress.city}`
        ]

        const contactPerson = organization.contactPersons.find(c => c.qualifiedForOrders)
        if (contactPerson) {
          const { gender, firstName, lastName } = contactPerson
          const nameString = `${gender === 'female' ? 'Fr.' : 'Hr.'} ${
            firstName ? `${firstName} ${lastName}` : lastName
          }\n`
          address.splice(1, 0, nameString)
        }

        docDefinition.content.push(
          {
            text: 'Emma GmbH – Am Mittelfelde 31 - 30519 Hannover',
            style: 'senderAddress'
          },
          {
            text: address,
            style: 'customerAddress'
          },
          {
            text: `Hannover, ${dateString}`,
            style: 'date'
          },
          {
            text: 'Weihnachtskatalog 2022',
            style: 'headline'
          },
          {
            text: [
              `\n Liebes ${organization.name}-Team,\n\n`,
              `anbei finden Sie`,
              {
                text: ` ${organization.catalogDemand} Weihnachtskataloge `,
                bold: true
              },
              'zum Verteilen an Ihre Bewohner/innen.\n\n',
              'Wir wünschen Ihnen und Ihren Familien eine schöne Weihnachtszeit.\n\n',
              'Viele Grüße aus Hannover\n\n',
              'Ihr Emma-Team'
            ],
            pageBreak: isLastItem ? false : 'after'
          }
        )
      })

      pdfMake.createPdf(docDefinition).download(`Weihnachtsanschreiben 2022.pdf`)
    }
  }
}
</script>

<style scoped>
.pa-4 {
  padding: 25px;
}

.org-btn-wrap {
  display: flex;
  justify-content: flex-end;
}
</style>
