<template>
  <button
    class="button"
    :style="{ background: color }"
    :type="type ? type : 'button'"
    :class="{ loading: isLoading, inverted: inverted, [type]: type }"
    @click="handleClick"
  >
    <span class="button__icon button__icon--before" v-if="type === 'back'">
      <svg>
        <use xlink:href="./button-sprite.svg#back" :style="`stroke: ${inverted ? '#4135a6' : '#fff'};`" />
      </svg>
    </span>
    <span class="button__text">
      <slot></slot>
    </span>
    <span class="button__icon button__icon--after" v-if="type === 'forward'">
      <svg>
        <use xlink:href="./button-sprite.svg#forward" :style="`stroke: ${inverted ? '#4135a6' : '#fff'};`" />
      </svg>
    </span>
    <div class="button__spinner">
      <div class="button__spinner__dot" />
      <div class="button__spinner__dot" />
      <div class="button__spinner__dot" />
    </div>
  </button>
</template>

<script>
export default {
  props: ['isLoading', 'inverted', 'type', 'to', 'color'],
  methods: {
    handleClick: function() {
      if (this.to) {
        this.$router.replace(this.to)
      } else {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../variables';

.button {
  @extend %cleanbutton;

  position: relative;
  padding: 0.5rem 1rem;

  background: $color-main;
  border-radius: 4rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  transition: background 0.2s ease;

  color: #fff;
  font-size: 0.9375rem;
  font-weight: $weight-bold;

  &:hover {
    background: lighten($color-main, 10%);
  }
  &:focus {
    background: lighten($color-main, 10%);
    box-shadow: 0 0 10px $color-main;
    outline: none;
  }

  &__icon {
    svg {
      width: 20px;
      height: 16px;

      stroke: #fff;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-linejoin: round;

      path {
        stroke: currentColor;
        stroke-width: inherit;
        stroke-linecap: inherit;
        stroke-linejoin: inherit;
      }
    }
    &--before {
      svg {
        margin: 2px 8px -2px 0;
      }
    }
    &--after {
      svg {
        margin: 2px 0 -2px 8px;
      }
    }
  }

  &__spinner {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    line-height: 40px;

    &__dot {
      display: inline-block;
      position: relative;

      width: 7px;
      height: 7px;
      margin: 0 2px;

      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: bounce;
      animation-timing-function: ease;
      background: #fff;
      border-radius: 50%;

      &:first-child {
        animation-delay: -0.1s;
      }
      &:last-child {
        animation-delay: 0.1s;
      }
    }
  }

  &.loading {
    .button__spinner {
      visibility: visible;
    }
    &:hover {
      background: $color-main;
      cursor: default;
    }

    .button__text {
      opacity: 0;
    }

    .button__icon {
      opacity: 0;
    }
  }

  &.inverted {
    background: #fff;

    color: $color-main;

    &:hover,
    &:focus {
      background: darken(#fff, 15%);
    }

    .button__spinner__dot {
      background: $color-main;
    }

    &.loading {
      &:hover {
        background: #fff;
      }
    }
  }
}

@keyframes bounce {
  0% {
    bottom: 0;
  }
  25% {
    bottom: 5px;
  }
  50% {
    bottom: 0;
  }
  100% {
    bottom: 0;
  }
}
</style>
