<template>
  <div class="TextField">
    <label v-if="label" :for="id" :class="{ TextField__label: true, inverted: inverted }">
      {{ label }}
      <span v-if="markAsOptional" class="optional-mark">(optional)</span>
    </label>
    <input
      class="TextField__input"
      :class="{ [type]: type, [styleClass]: styleClass }"
      @input="handleInput"
      @keydown="onKeyDown"
      @blur="$emit('blur')"
      :disabled="disabled"
      :placeholder="placeholder"
      :type="type"
      :value="value"
      :name="name"
      :id="id"
      :required="required"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
    />
    <span v-if="suffix" class="TextField__suffix">{{ suffix }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null
    }
  },
  props: {
    type: { type: String, default: 'text' },
    label: { type: String },
    placeholder: { type: String },
    name: { type: String },
    inverted: { type: Boolean, default: false },
    value: { type: String },
    styleClass: { type: String, default: 'squared' },
    preventOnKeyDown: { type: Array },
    required: { type: Boolean, default: false },
    markAsOptional: { type: Boolean, default: false },
    suffix: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    autofocus: { type: String, default: 'off' },
    autocomplete: { type: String, default: 'off' }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    },
    onKeyDown(e) {
      if (this.preventOnKeyDown && this.preventOnKeyDown.indexOf(e.key) !== -1) {
        e.preventDefault()
      }
      this.$emit(e.type, e)
    }
  },
  mounted() {
    this.id = this._uid
  }
}
</script>

<style lang="scss" scoped>
@import '../variables';

.TextField {
  margin: 0 0 1rem;
  position: relative;

  &__label {
    display: block;
    margin: 0 0 0.75rem 0.75rem;

    color: #000;
    font-weight: &weight-medium;
    line-height: 1.3em;

    &.inverted {
      color: #fff;
    }
  }

  &__input {
    width: 100%;
    height: 2.5rem;

    background: #fff;

    color: #000;
    font-size: 0.9375rem;
    text-indent: 0.25rem;
    font-family: inherit;
    text-indent: 0.75rem;

    &::placeholder {
      color: #777;
      font-family: inherit;
    }

    &:focus {
      outline: none;
    }

    &::-ms-clear {
      display: none;
    }

    &.squared {
      border: 1px #ddd solid;
      border-radius: 5px;
    }

    &.rounded {
      background-color: #fafafa;
      border-radius: 1.25rem;
      border: none;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25) inset;
    }

    &.center {
      text-align: center;
      text-indent: 0;
    }

    &.search {
      -webkit-appearance: none;
      background-image: url(./search.svg);
      background-position: calc(100% - 1rem) center;
      background-repeat: no-repeat;
      text-indent: 1.25rem;

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }

    &.number {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .optional-mark {
    color: #777;
    text-transform: uppercase;
    font-weight: $weight-bold;
    font-size: 0.8em;
  }
  &__suffix {
    position: absolute;
    bottom: 0.625rem;
    right: 1rem;
  }
}
</style>
